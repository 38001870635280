// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"



// import "jquery-migrate"
// require("themes/script")

// require('jquery')
// require('popper.js/dist/umd/popper')
// require('bootstrap')
// require('appearjs')
// require('appear/dist/appear')
// require('jquery-countdown')

// require('assets/vendor/hs-megamenu/src/hs.megamenu')
// require('svg-injector')
// require('malihu-custom-scrollbar-plugin')
// require('jquery-validation')
// require('fancybox')
// require('typed.js/lib/typed')
// require('slick-carousel')
// require('bootstrap-select')

// require('assets/js/hs.core')
// require('assets/js/components/hs.countdown')
// require('assets/js/components/hs.header')
// require('assets/js/components/hs.hamburgers')
// require('assets/js/components/hs.unfold')
// require('assets/js/components/hs.focus-state')
// require('assets/js/components/hs.malihu-scrollbar')
// require('assets/js/components/hs.validation')
// require('assets/js/components/hs.fancybox')
// require('assets/js/components/hs.onscroll-animation')
// require('assets/js/components/hs.slick-carousel')
// require('assets/js/components/hs.show-animation')
// require('assets/js/components/hs.svg-injector')
// require('assets/js/components/hs.go-to')
// require('assets/js/components/hs.selectpicker')

Rails.start()
Turbolinks.start()
ActiveStorage.start()
